export const colors = {
  lightBlack1: '#4E5459',
  lightBlack2: '#4E5459',
  lightBlack3: '#4E5459',
  lightBlack4: '#4E5459',
  darkBlack1: '#161B1E',
  darkBlack3: '#161B1E',
  darkBlueGrey: '#161b1e',
  pink: '#EA3796',
  yellow: '#FAB43C',
  white1: '#FBF6E4',
  orange: '#EB5C34',

  black:"#000000"
};

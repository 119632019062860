import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { colors } from '../constants/colors';

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: '#E40C7E',
    },
    secondary: {
      main: colors.yellow,
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    allVariants: {
      color: colors.white1,
    },
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    fontFamily: `'Questrial','Open Sans', sans-serif`,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors.white1,
          borderBottomColor: colors.lightBlack2,
        },
      },
    },
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: colors.lightBlack3,
    //     }
    //   }
    // },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.white1,
          borderRadius: 4,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: colors.white1,
        fontFamily: `'Open Sans' sans-serif`,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: colors.darkBlack3,
            color: colors.white1,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: colors.lightBlack4,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: colors.white1,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.pink,
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: colors.darkBlack3,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          background: colors.darkBlack1,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: colors.pink,
            color: colors.white1,
          },
          ':hover': {
            background: 'rgba(228, 12, 126, 0.5)',
            color: colors.white1,
          },
          background: colors.white1,
          color: colors.darkBlack1,
          justifyContent: 'flex-start',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          overflow: 'hidden',
        },
      },
    },
  },
});

// theme.typography.

theme = responsiveFontSizes(theme);

export default theme;

import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../utils/createEmotionCache';
import theme from '../utils/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'next-auth/react';
import { NextIntlProvider } from 'next-intl';
import { SnackAlert } from 'src/components/common/SnackAlert';
import { useRouter } from 'next/router';
import Script from 'next/script';
import * as gtag from 'src/lib/gtag';
import 'src/styles/globals.css';
import '../../public/fonts/openSans/index.css';
const queryClient = new QueryClient();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export const AppContext = React.createContext<{
  navOpen: boolean;
  setNavOpen: (open: boolean) => void;
  contactUsModalOpen: boolean;
  setContactUsModalOpen: (open: boolean) => void;
  setSnackData: (
    snackData: {
      severity: 'info' | 'error' | 'warning' | 'success';
      message: string;
      duration?: number;
    } | null
  ) => void;
  openRiskAssessmentModal: boolean;
  setOpenRiskAssessmentModal: (open: boolean) => void;
  openRequestInvestmentModal: boolean;
  setOpenRequestInvestmentModal: (open: boolean) => void;
  openKYCModal: boolean;
  setOpenKYCModal: (open: boolean) => void;
  clickedOfferId: string;
  setClickedOfferId: React.Dispatch<React.SetStateAction<string>>;
}>({
  navOpen: true,
  setNavOpen: () => null,
  contactUsModalOpen: true,
  setContactUsModalOpen: () => null,
  setSnackData: () => {},
  openRiskAssessmentModal: false,
  setOpenRiskAssessmentModal: () => null,
  openRequestInvestmentModal: false,
  setOpenRequestInvestmentModal: () => null,
  openKYCModal: false,
  setOpenKYCModal: () => null,
  clickedOfferId: '',
  setClickedOfferId: () => null,
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: any;
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [navOpen, setNavOpen] = React.useState(false);
  const [contactUsModalOpen, setContactUsModalOpen] = React.useState(false);
  const [openRequestInvestmentModal, setOpenRequestInvestmentModal] =
    React.useState(false);
  const [openRiskAssessmentModal, setOpenRiskAssessmentModal] =
    React.useState(false);
  const [openKYCModal, setOpenKYCModal] = React.useState(false);
  const [snackData, setSnackData] = React.useState<{
    severity: 'info' | 'error' | 'warning' | 'success';
    message: string;
  } | null>(null);
  const [clickedOfferId, setClickedOfferId] = React.useState('');
  const router = useRouter();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <NextIntlProvider
      // To achieve consistent date, time and number formatting
      // across the app, you can define a set of global formats.
      formats={{
        dateTime: {
          short: {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          },
        },
      }}
      messages={pageProps.messages}
      // Providing an explicit value for `now` ensures consistent formatting of
      // relative values regardless of the server or client environment.
      now={new Date(pageProps.now)}
      // Also an explicit time zone is helpful to ensure dates render the
      // same way on the client as on the server, which might be located
      // in a different time zone.
      timeZone="Europe/London"
    >
      <SessionProvider session={pageProps.session}>
        <AppContext.Provider
          value={{
            navOpen,
            setNavOpen,
            contactUsModalOpen,
            setContactUsModalOpen,
            setSnackData,
            openRiskAssessmentModal,
            setOpenRiskAssessmentModal,
            openRequestInvestmentModal,
            setOpenRequestInvestmentModal,
            openKYCModal,
            setOpenKYCModal,
            clickedOfferId,
            setClickedOfferId
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CacheProvider value={emotionCache}>
              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1, width=device-width"
                />
                <title>AssetTribe</title>
              </Head>
              <ThemeProvider theme={theme}>
                {/* <Script
                  id="gtm"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-P4HT3WB');`,
                  }}
                ></Script> */}
                {/* {process.env.NODE_ENV === 'production' && ( */}
                <Script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.js"></Script>
                <Script src="https://cdnjs.com/libraries/pdf.js"></Script>
                <Script src="https://cdn.dwolla.com/1/dwolla.js"></Script>
                {
                  <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                  />
                }
                {/* {process.env.NODE_ENV === 'production' && ( */}
                {
                  <Script
                    id="gtag-init"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                    });
                  `,
                    }}
                  />
                }
                {/* Google Analytics Tag:
                <!-- Google tag (gtag.js) --> */}
                {
                  <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=G-TCZKHXLJHG`}
                  />
                }
                {
                  <Script
                    id="gtag-1"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-TCZKHXLJHG');
                  `,
                    }}
                  />
                }
                {/* Data Stream Tag:  */}
                {/* <!-- Google tag (gtag.js) --> */}
                {
                  <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=G-PCZ0WPKQQ5`}
                  />
                }
                {
                  <Script
                    id="gtag-2"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-PCZ0WPKQQ5');
                  `,
                    }}
                  />
                }
                <Script
                  id="linkedin-partner"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    _linkedin_partner_id = '3951980';
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                  `,
                  }}
                />
                <Script
                  id="linkedin-init"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    (function (l) {
                      if (!l) {
                        window.lintrk = function (a, b) {
                          window.lintrk.q.push([a, b]);
                        };
                        window.lintrk.q = [];
                      }
                      var s = document.getElementsByTagName('script')[0];
                      var b = document.createElement('script');
                      b.type = 'text/javascript';
                      b.async = true;
                      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                      s.parentNode.insertBefore(b, s);
                    })(window.lintrk);
                  `,
                  }}
                />
                <Script
                  id="reporting-site-tag"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    function clear_reports_SiteTag_87() {
                      var cr_cid = 0;
                      var tp_cid = '';
                      var params = window.location.search.substring(1).split('&');
                      for (var i = 0; i < params.length; i++) {
                        var pair = params[i].split('=');
                        if (pair[0] == 'cr_cid' && pair[1]) {
                          cr_cid = parseInt(pair[1]);
                        } else if (pair[0] == 'gclid' && pair[1]) {
                          tp_cid = pair[1];
                        }
                      }
                      if (cr_cid || tp_cid) {
                        var d = new Date();
                        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
                        var expires = 'expires=' + d.toUTCString() + '; ';
                        var hostnameParts = window.location.hostname.split('.').reverse();
                        var domain = hostnameParts[0];
                        for (var j = 1; j < hostnameParts.length; j++) {
                          domain = hostnameParts[j] + '.' + domain;
                          document.cookie =
                            'cr___cd=1; ' + expires + 'domain=' + domain + '; path=/';
                          if (document.cookie.indexOf('cr___cd=1') > -1) break;
                        }
                        var cookieEnd = '; ' + expires + 'domain=' + domain + '; path=/';
                        document.cookie = 'cr___cd=' + domain + cookieEnd;
                        if (cr_cid) {
                          document.cookie = 'cr___cid=' + cr_cid + cookieEnd;
                        }
                        if (tp_cid) {
                          document.cookie = 'cr___tpcid=' + tp_cid + cookieEnd;
                        }
                      }
                    }
                    clear_reports_SiteTag_87();
                  `,
                  }}
                />
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...pageProps} />
              </ThemeProvider>
            </CacheProvider>
          </QueryClientProvider>
          <SnackAlert snackData={snackData} setSnackData={setSnackData} />
        </AppContext.Provider>
      </SessionProvider>
    </NextIntlProvider>
  );
}

export default MyApp;
